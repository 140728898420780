<template>
  <div class="thread-box" @click="threadBoxClicked">
    <!-- email subject -->
    <div class="row">
      <strong>{{ thread?.latest_draft_or_message?.subject }} </strong>
    </div>

    <!-- from address -->
    <div class="row">
      <strong
        >From: {{ thread?.latest_draft_or_message?.from[0].email }}</strong
      >
    </div>

    <!-- snippet of the last message in the thread -->
    <div class="snippet row">{{ thread?.snippet }}</div>

    <!-- time -->
    <div class="time">
      {{ formattedTime(thread?.latest_draft_or_message?.created_at) }}
    </div>
  </div>
</template>

<script>
import { convertUnixTimeToDDMMYYHHMM } from "../global-helpers";

export default {
  name: "ThreadBox",

  emits: ["threadBoxClicked"],

  props: {
    thread: {
      type: Object,
      required: true,
    },
  },

  methods: {
    threadBoxClicked() {
      this.$emit("threadBoxClicked", this.thread);
    },

    formattedTime(time) {
      return convertUnixTimeToDDMMYYHHMM(time);
    },
  },
};
</script>

<style scoped>
.thread-box {
  border-radius: 10px;
  border: 1px solid #b7b1b1;
  padding: 5px 10px;
  margin: 5px 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: rgb(255, 255, 251);
  height: 110px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 22px;
}

.snippet {
  line-height: 15px;
  width: 460px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  height: 40px;
}

.row {
  margin-top: 2px;
}

.time {
  font-size: 12px;
  margin-top: auto;
  margin-left: auto;
}
</style>
