<template>
    <router-view> </router-view>
</template>

<script>
export default {
    name: 'App'
};
</script>

<style>
#app {
    font-family: spezia, Avenir, Helvetica, Arial, sans-serif;
}
</style>
