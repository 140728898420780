import { createRouter, createWebHistory } from "vue-router";
import HomeScreen from "./components/Screens/HomeScreen.vue";
import {
  getAccessTokenFromLocalStorage,
  getGrantIdFromLocalStorage,
} from "./global-helpers";
import NewLoginScreen from "@/components/Screens/NewLoginScreen.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "", redirect: "/login", component: NewLoginScreen },

    { name: "login", path: "/login", component: NewLoginScreen },

    {
      name: "home",
      path: "/home",
      component: HomeScreen,
      beforeEnter: async (to, from, next) => {
        const accessToken = getAccessTokenFromLocalStorage();
        const grantId = getGrantIdFromLocalStorage();
        if (accessToken && grantId) {
          next();
        } else {
          // the user is unauthorized to reach this page, reroute him to the "login" page
          next("/login");
        }
      },
    },

    // this path should be the last one in this configuration
    // every unknown path will redirect the user to the home page
    {
      path: "/:notFound(.*)",
      component: NewLoginScreen,
      beforeEnter: (to, from, next) => {
        next("/login");
      },
    },
  ],

  // scroll the user to the top of a page when he is moving between the pages
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 };
  },
});

// this is called whenever the user navigates between the pages and also when he refreshes the page
// it is used to guard some routes
router.beforeEach(async (to, from, next) => {
  next();
});

export default router;
