import { createApp } from "vue";
import App from "./App.vue";
import router from "./router.js";
import { vivid3 } from "@vonage/vivid-vue";

const app = createApp(App);

app.use(router);
app.use(vivid3, {
  font: "spezia",
});

app.mount("#app");
