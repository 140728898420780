<template>
  <div class="message-box">
    <!-- sender name -->
    <p class="message-sender-name" v-if="message?.from[0].name">
      {{ message?.from[0].name }} : {{ message?.from[0].email }}
    </p>

    <p class="message-sender-name" v-else>
      {{ message?.from[0].email }}
    </p>
    <!-- message content -->
    <p class="message-content" :class="{ 'limit-content-height': !readMore }">
      <iframe
        :style="{ height: `${iframeHeight}px` }"
        class="message-iframe"
        sandbox="allow-same-origin allow-scripts"
        ref="messageIframe"
        @load="setIframeHeight"
      ></iframe>

      <VButton
        v-if="!readMore && iframeHeight > 400"
        label="Read more..."
        appearance="outlined"
        class="read-more"
        @click="readMore = true"
        size="condensed"
      ></VButton>
    </p>

    <div class="message-right-container">
      <!-- message time -->
      <p class="message-time">
        {{ formattedTime(message?.created_at) }}
      </p>
      <!-- delete message button -->
      <VButton
        appearance="ghost"
        class="delete-message"
        @click="deleteMessageClicked()"
        icon="delete-solid"
        size="super-condensed"
        connotation="accent"
      ></VButton>
    </div>
  </div>
</template>

<script>
import { convertUnixTimeToDDMMYYHHMM } from "../global-helpers";
import { VButton } from "@vonage/vivid-vue";

export default {
  name: "MessageBox",

  components: {
    VButton,
  },

  emits: ["deleteMessageClicked"],

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      iframeHeight: 0,
      readMore: false,
    };
  },

  mounted() {
    const doc = this.$refs.messageIframe.contentWindow.document;
    const content =
      "<style>body { font-family: Avenir, Helvetica, Arial, sans-serif; }</style>" +
      this.message?.body;
    doc.open();
    doc.write(content);
    doc.close();
  },

  methods: {
    deleteMessageClicked() {
      this.$emit("deleteMessageClicked");
    },

    formattedTime(time) {
      return convertUnixTimeToDDMMYYHHMM(time);
    },

    setIframeHeight() {
      setTimeout(() => {
        const docElement =
          this.$refs.messageIframe?.contentDocument?.documentElement;
        this.iframeHeight = docElement ? docElement.scrollHeight : 100;
      }, 0);
    },
  },
};
</script>

<style scoped>
.message-box {
  border-radius: 10px;
  border: 1.5px solid #323030;
  padding: 5px 10px;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}

.delete-message {
  margin-left: 4px;
}

.message-sender-name {
  font-weight: 600;
  font-size: 17px;
}

p {
  font-size: 14px;
}

.message-right-container {
  margin: 0;
  align-self: flex-end;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message-content {
  display: flex;
  flex-direction: column;
}

.message-iframe {
  border: none;
  border-radius: 10px;
  padding: 8px;
  height: 100%;
}

.limit-content-height {
  max-height: 185px;
}

.message-time {
  padding-top: 4px;
}

.read-more {
  width: 100px;
  margin-top: 8px;
  margin-left: 4px;
}
</style>
