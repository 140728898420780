import router from "./router";
import {
  ACCESS_TOKEN_NAME_IN_LOCAL_STORAGE,
  GRANT_ID_NAME_IN_LOCAL_STORAGE,
  EMAIL_NAME_IN_LOCAL_STORAGE,
} from "./global-consts";

export function routeToHomeScreen() {
  router.push("/home");
}

export function routeToLoginScreen() {
  router.push("/login");
}

export function setAccessTokenInLocalStorage(accessToken) {
  localStorage.setItem(ACCESS_TOKEN_NAME_IN_LOCAL_STORAGE, accessToken);
}

export function resetAccessTokenInLocalStorage() {
  localStorage.removeItem(ACCESS_TOKEN_NAME_IN_LOCAL_STORAGE);
}

export function getAccessTokenFromLocalStorage() {
  return localStorage.getItem(ACCESS_TOKEN_NAME_IN_LOCAL_STORAGE);
}

export function setGrantIdInLocalStorage(grantId) {
  localStorage.setItem(GRANT_ID_NAME_IN_LOCAL_STORAGE, grantId);
}

export function resetGrantIdInLocalStorage() {
  localStorage.removeItem(GRANT_ID_NAME_IN_LOCAL_STORAGE);
}

export function getGrantIdFromLocalStorage() {
  return localStorage.getItem(GRANT_ID_NAME_IN_LOCAL_STORAGE);
}

export function setEmailAddressInLocalStorage(email) {
  localStorage.setItem(EMAIL_NAME_IN_LOCAL_STORAGE, email);
}

export function resetEmailAddressInLocalStorage() {
  localStorage.removeItem(EMAIL_NAME_IN_LOCAL_STORAGE);
}

export function getEmailAddressFromLocalStorage() {
  return localStorage.getItem(EMAIL_NAME_IN_LOCAL_STORAGE);
}

export function resetUserDetails() {
  resetAccessTokenInLocalStorage();
  resetGrantIdInLocalStorage();
  resetEmailAddressInLocalStorage();
}

export function setUserDetails(accessToken, grantId, email) {
  setAccessTokenInLocalStorage(accessToken);
  setGrantIdInLocalStorage(grantId);
  setEmailAddressInLocalStorage(email);
}

export function logOutUser() {
  // reset user details
  resetUserDetails();

  // reroute the user to login page
  routeToLoginScreen();
}

export function convertUnixTimeToHHMM(timestamp) {
  // convert Unix timestamp to milliseconds
  const milliseconds = timestamp * 1000;

  // create a new Date object
  const dateObject = new Date(milliseconds);

  // extract hours and minutes
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  // return the formatted time
  return hours + ":" + minutes;
}

export function convertUnixTimeToDDMMYYHHMM(timestamp) {
  // convert Unix timestamp to milliseconds
  const milliseconds = timestamp * 1000;

  // create a new Date object
  const dateObject = new Date(milliseconds);

  // extract day, month, year, hours, and minutes
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear().toString().substr(-2);
  const hours = dateObject.getHours().toString().padStart(2, "0");
  const minutes = dateObject.getMinutes().toString().padStart(2, "0");

  // return the formatted date and time
  return `${day}/${month}/${year}     ${hours}:${minutes}`;
}

export function openCenteredPopupWindow(url, title, windowWidth, windowHeight) {
  // Calculate the position for the new window to be centered
  const left = (window.screen.width - windowWidth) / 2;
  const top = (window.screen.height - windowHeight) / 2;

  // Additional features for the window
  const features = `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;

  // Open the new window
  window.open(url, title, features);
}
