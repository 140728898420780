<template>
    <div class="top-bar">
        <img class="vonage-logo" src="../../public/vonage-logo.svg" />
        <div class="email-line">
            <span>
                You are logged in as: <strong>{{ email }}</strong>
            </span>

            <VButton
                appearance="filled"
                label="Disconnect User"
                icon=""
                class="disconnect-button"
                connotation="cta"
                :pending="isDisconnectButtonClicked"
                @click="disconnectUserClicked"
            />
        </div>
    </div>
</template>

<script>
import { VButton } from '@vonage/vivid-vue';
import axios from 'axios';
import {
    getAccessTokenFromLocalStorage,
    getGrantIdFromLocalStorage,
    getEmailAddressFromLocalStorage,
    logOutUser
} from '../global-helpers';

export default {
    name: 'TopBar',

    components: {
        VButton
    },

    data() {
        return {
            accessToken: undefined,
            grantId: undefined,
            email: '',
            isDisconnectButtonClicked: false
        };
    },

    mounted() {
        // get user details from local storage
        this.accessToken = getAccessTokenFromLocalStorage();
        this.grantId = getGrantIdFromLocalStorage();
        this.email = getEmailAddressFromLocalStorage();
    },

    methods: {
        async disconnectUserClicked() {
            // In case the user did multiple clicks before the button responded
            if (this.isDisconnectButtonClicked) {
                return;
            }

            // for UI indication that we are processing the request
            this.isDisconnectButtonClicked = true;
            const URL = `https://email-service.vonage.com/grant/${this.grantId}`;
            try {
                await axios.delete(URL, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
            } catch (err) {
                // do nothing
            } finally {
                logOutUser();
                this.disconnectUserClicked = false;
            }
        }
    }
};
</script>

<style scoped>
.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    width: 100vw;
    padding: 8px;
    z-index: 0;
}

.vonage-logo {
    height: 30px;
    margin-left: 20px;
}

.email-line {
    display: flex;
    align-items: center;
    color: white;
}

.disconnect-button {
    margin: 0 15px;
    z-index: 900;
    cursor: pointer;
}
</style>
