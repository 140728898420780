<template>
    <div class="container">
      <div class="login-background-container">
        <img class="login-background" src="../../../public/NewLoginBackground.svg" />
      </div>
      <div class="sign-up-container">
        <div class="sign-up">
          <div class="content-wrapper">
            <div class="text-container">
              <div class="welcome">Ready to get started?</div>
              <div class="sign">Sign in to connect your Gmail account with the Vonage Email Platform</div>
            </div>
            <img
                class="sign-with-button"
                src="../../../public/sign_in_with_google.png"
                @click="signUpClicked"
            />
            <div class="contact-container">
              <div class="contact">Don’t have an account yet?</div>
              <a class="contact-us" href="https://support.google.com/accounts/answer/27441?hl=en"> Contact us </a>
            </div>
          </div>
        </div>
        <div class="bottom-buttons-container">
          <a class="privacy" href="https://www.vonage.com/legal/privacy-policy/"> Privacy </a>
          <a class="terms" href="https://www.vonage.com/legal/contact-centers/msa"> Terms </a>
        </div>
      </div>
      <div v-if="error" class="error">{{ error }}</div>
    </div>
</template>

<script>
import axios from 'axios';
import { routeToHomeScreen, setUserDetails, openCenteredPopupWindow } from '../../global-helpers';

const PROVIDER = 'google';

export default {
    name: 'NewLoginScreen',

    data() {
        return {
            error: ''
        };
    },

    mounted() {
        // add a listener for window messages from the server
        window.addEventListener('message', (event) => {
            if(!['https://email-service.vonage.com', 'https://email-service.vonage.com'].includes(event.origin)) {
                return;
            }

            console.log(`event received: ${JSON.stringify(event)}`);

            const postMessageData = event.data;
            if (postMessageData.status === 'success') {
                this.error = '';
                // save user details in the local storage
                setUserDetails(postMessageData.accessToken, postMessageData.grantId, postMessageData.email);

                // redirect the user to the home page
                routeToHomeScreen();
            } else if (postMessageData.status === 'failed') {
                // show indication in the UI that auth flow did not succeeded (?)
                this.error = 'Auth flow failed, please try again';
            }
        });
    },

    methods: {
        async signUpClicked() {
            this.error = '';

            // send GET request to the server in order to receive the auth url
            // pass the provider & the origin domain
            const origin = window.location.origin;
            const URL = `https://email-service.vonage.com/auth-url?provider=${PROVIDER}&origin_domain=${origin}`;
            const response = await axios.get(URL);

            // extract the auth url from the response
            const authUrl = response.data;

            // open the received Auth URL on a new pop up window
            openCenteredPopupWindow(authUrl, 'authWindow', 500, 600);
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    width: 100vw;
    height: 100vh;
    margin-top: -8px;
    margin-left: -8px;
}

.login-background-container {
    width: 80vw;
    height: 100vh;
}

.login-background {
    width: 75vw;
    height: 100vh;
    object-fit: cover;
}

.sign-up-container {
    display: flex;
    justify-content: center;
    width: 25vw;
    height: 100vh;
    background: #FFFFFF;
    margin-top: 15%;
}

.welcome {
    font-size: 32px;
}

.sign {
    font-size: 18px;
}

.contact-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact {
    margin-right: 5px;
    font-size: 14px;
}

.contact-us {
    font-size: 14px;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    margin-top: 10px;
    margin-right: 10px
}

.text-container .sign {
    margin-top: 10px;
}

.sign-with-button {
    margin-top: 40px;
    width: 200px;
    cursor: pointer;
}

.vonage-logo {
    width: 70px;
    height: 70px;
}

.error {
    color: red;
    font-size: 12px;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    border-radius: 20px;
    border: 1.5px solid #000000;
    background-color: white;
    height: 550px;
    width: 400px;
    position: relative;
}

.bottom-buttons-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 10px;
    font-size: 12px;
    color: rgb(9, 9, 130);
    cursor: pointer;
}

.bottom-buttons-container .terms {
    margin-left: 10px;
}

.content-wrapper {
    padding: 10px 0px 10px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
