<template>
    <div class="container">
        <!-- Flash Message -->
        <VAlert
            class="error-flash-message"
            :text="errorFlashMessageText"
            :open="shouldShowErrorFlashMessage"
            connotation="alert"
            placement="top-end"
            removable
            :timeoutms="flashMessageTimeout"
        ></VAlert>

        <!-- Top Bar -->
        <TopBar />

        <!-- Screen Content -->
        <div class="row-container">
            <div class="box-container">
                <!-- Threads view -->
                <div class="left-side">
                    <!-- Threads title -->
                    <div class="threads-title">
                        <div>Recent Emails</div>
                        <VButton
                            class="refresh-button"
                            icon="reload-solid"
                            size="super-condensed"
                            shape="pill"
                            connotation="cta"
                            appearance="filled"
                            @click="refreshThreads"
                        ></VButton>
                    </div>
                    <!-- Threads List -->
                    <div class="threads-container">
                        <VProgressRing class="loading-threads-ring" v-if="loadingThreads"></VProgressRing>
                        <template v-else>
                            <ThreadBox
                                v-for="thread in threads"
                                :key="thread.id"
                                :thread="thread"
                                @threadBoxClicked="threadClicked(thread)"
                                :class="{ selected: isThreadSelected(thread.id) }"
                            ></ThreadBox>
                        </template>
                    </div>
                </div>

                <!-- Messages view -->
                <div class="right-side">
                    <!-- Messages container -->
                    <div class="messages-container" v-if="messagesView.threadId">
                        <!-- Thread subject title -->
                        <div class="thread-subject">{{ messagesView.subject }}</div>

                        <div class="messages-container-container" ref="messagesContainer">
                            <!-- Message boxes -->
                            <VProgressRing
                                class="messages-loader"
                                v-if="loadingMessages && messagesView.messages.length === 0"
                            ></VProgressRing>
                            <template v-else>
                                <MessageBox
                                    v-for="message in messagesView.messages"
                                    :key="message.id"
                                    :message="message"
                                    @deleteMessageClicked="deleteMessageByMessageId(message.id)"
                                ></MessageBox>
                            </template>
                        </div>
                        <!-- Reply area -->
                        <div class="reply-container">
                            <!-- Textarea -->
                            <VTextArea class="textarea" v-model="userReply.body" :rows="3"></VTextArea>

                            <!-- Reply Button -->
                            <VButton
                                label="Reply"
                                class="reply-button"
                                connotation="cta"
                                appearance="filled"
                                @click="replyThreadClicked"
                                :disabled="!userReply.body || replyInProgress"
                            />
                        </div>
                    </div>

                    <div class="messages-empty-state" v-else>
                        <VEmptyState icon="mailbox-line" headline="Select an email to view messages"></VEmptyState>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { getAccessTokenFromLocalStorage, getEmailAddressFromLocalStorage, logOutUser } from '../../global-helpers';
import { TOKEN_EXPIRATION_IN_MILlISECONDS } from '../../global-consts';
import ThreadBox from '../ThreadBox.vue';
import MessageBox from '../MessageBox.vue';
import TopBar from '../TopBar.vue';
import { VButton, VTextArea, VEmptyState, VProgressRing, VAlert } from '@vonage/vivid-vue';

const POLLING_THREADS_TIME_IN_MILLISECONDS = 5000; //  1 minute (60 seconds * 1000 milliseconds)

export default {
    name: 'HomeScreen',

    components: {
        ThreadBox,
        MessageBox,
        TopBar,
        VButton,
        VTextArea,
        VEmptyState,
        VProgressRing,
        VAlert
    },

    data() {
        return {
            accessToken: undefined,
            email: '',
            limit: 20,
            userReply: {
                body: ''
            },
            errorFlashMessageText: '',
            threads: [],
            intervalId: undefined,
            messagesView: {
                threadId: '',
                to: [],
                messagesIds: [],
                messages: [],
                subject: '',
                lastMessageId: ''
            },
            loadingThreads: false,
            loadingMessages: false,
            replyInProgress: false,
            flashMessageTimeout: 4000
        };
    },

    mounted() {
        // get user details from local storage
        this.accessToken = getAccessTokenFromLocalStorage();
        this.email = getEmailAddressFromLocalStorage();

        // start timer to logout the user when the accessToken expired
        this.logOutUserTimer();

        // get threads for the first time
        this.loadingThreads = true;
        this.getThreads().then(() => {
            this.loadingThreads = false;
        });

        // start polling threads
        this.startPollingThreads();
    },

    beforeUnmount() {
        // stop polling threads before the component is destroyed
        this.stopPollingThreads();
    },

    computed: {
        shouldShowErrorFlashMessage() {
            return this.errorFlashMessageText !== '';
        }
    },

    methods: {
        scrollToBottom() {
            this.$refs.messagesContainer.scrollTop =
                this.$refs.messagesContainer.scrollHeight - this.$refs.messagesContainer.clientHeight;
        },

        async resetErrorFlashMessageText() {
            await new Promise((resolve) => {
                setTimeout(async () => {
                    this.errorFlashMessageText = '';
                    resolve();
                }, this.flashMessageTimeout);
            });
        },

        setErrorFlashMessage(errorText) {
            this.errorFlashMessageText = errorText;
            this.resetErrorFlashMessageText();
        },

        async refreshThreads() {
            this.loadingThreads = true;
            await this.getThreads();
            this.loadingThreads = false;
        },

        isThreadSelected(threadId) {
            return threadId === this.messagesView.threadId;
        },

        async logOutUserTimer() {
            await new Promise((resolve) => {
                setTimeout(async () => {
                    logOutUser();
                    resolve();
                }, TOKEN_EXPIRATION_IN_MILlISECONDS);
            });
        },

        startPollingThreads() {
            this.intervalId = setInterval(() => {
                this.getThreads();
            }, POLLING_THREADS_TIME_IN_MILLISECONDS);
        },

        stopPollingThreads() {
            clearInterval(this.intervalId);
        },

        handleErrors(err, errorText) {
            if (err?.response?.status === 401) {
                // access token expired or invalid
                // reroute the user to login page to login again his user
                logOutUser();
            } else {
                if (errorText) {
                    this.setErrorFlashMessage(errorText);
                }
            }
        },

        resetMessagesView() {
            this.messagesView = {
                threadId: '',
                to: [],
                messagesIds: [],
                messages: [],
                subject: '',
                lastMessageId: ''
            };
        },

        async threadClicked(thread) {
            this.resetMessagesView();
            await this.getThreadMessages(thread);
        },

        async replyThreadClicked() {
            this.replyInProgress = true;
            const emailRecipient = this.messagesView.messages.find(message => message.id === this.messagesView.lastMessageId)?.from[0]?.email;
            const requestBody = {
                to: [{email: emailRecipient}],
                body: this.userReply.body,
                subject: this.messagesView.subject,
                reply_to_message_id: this.messagesView.lastMessageId
            };

            // reset the text in the textarea
            this.userReply.body = '';

            let replyResponse;

            try {
                const URL = `https://email-service.vonage.com/messages`;
                const response = await axios.post(URL, requestBody, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                replyResponse = response.data;
            } catch (err) {
                this.handleErrors(err, 'Failed to send message');
            }

            try {
                const message = await this.getMessageByMessageId(replyResponse.data.id);
                this.messagesView.messages.push(message);
                setTimeout(() => {
                    this.scrollToBottom();
                }, 200);
            } catch (err) {
                // do nothing
            } finally {
                this.replyInProgress = false;
            }
        },

        filteredThreads(threads) {
            return (
                threads
                    .filter((thread) => {
                        // remove threads that have only one email that I have sent + remove threads that in trash
                        return !(thread.message_ids.length === 1 && thread.folders.includes('SENT')) && !thread.folders.includes('TRASH');
                    })
                    // take only the first 5 emails
                    .slice(0, 5)
            );
        },

        setThreads(threadsArray) {
            this.threads = threadsArray;
        },

        async getThreads() {
            try {
                const URL = `https://email-service.vonage.com/threads?&limit=${this.limit}`;
                const response = await axios.get(URL, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                const filteredThreads = this.filteredThreads(response.data.data);
                this.setThreads(filteredThreads);
            } catch (err) {
                this.handleErrors(err, 'Failed to fetch emails');
            }
        },

        async getMessageByMessageId(messageId) {
            const URL = `https://email-service.vonage.com/messages/${messageId}`;
            try {
                const response = await axios.get(URL, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                return response.data.data;
            } catch (err) {
                this.handleErrors(err);
            }
        },

        async getMessagesByMessageIdsArray() {
            this.messagesView.messages = [];
            this.loadingMessages = true;

            for (const messageId of this.messagesView.messagesIds) {
                const message = await this.getMessageByMessageId(messageId);
                // don't show deleted messages
                if (!message.folders.includes('TRASH')) {
                    this.messagesView.messages.push(message);
                }
            }
            this.loadingMessages = false;
        },

        async getThreadMessages(thread) {
            // set needed data for messages view of the selected thread
            this.messagesView.threadId = thread?.id;
            this.messagesView.to = thread?.latest_draft_or_message?.to;
            this.messagesView.messagesIds = thread?.message_ids;
            this.messagesView.subject = thread?.latest_draft_or_message?.subject;
            const lastMessageIdIndex = this.messagesView.messagesIds.length - 1;
            this.messagesView.lastMessageId = this.messagesView.messagesIds[lastMessageIdIndex];

            // fetch all the messages of this thread by the messagesIds array
            await this.getMessagesByMessageIdsArray();
        },

        async deleteMessageByMessageId(messageId) {
            const URL = `https://email-service.vonage.com/messages/${messageId}`;
            try {
                await axios.delete(URL, {
                    headers: {
                        Authorization: `Bearer ${this.accessToken}`
                    }
                });
                this.resetMessagesView();
                await this.getThreads();
            } catch (err) {
                this.handleErrors(err, 'Failed to delete message');
            }
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -8px;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.row-container {
    display: flex;
    height: 93vh;
    flex-direction: row;
    background-color: rgb(248, 244, 244);
}

.left-side,
.right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.left-side {
    margin: 20px 0px 20px 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    width: 500px;
    background-color: #f9ebfe;
}

.right-side {
    margin: 20px 20px 20px 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 10px;
    flex: 3;
}

.title {
    font-weight: 600;
    font-size: 30px;
}

.reply-button {
    width: 150px;
    margin-left: 10px;
    padding: 10px 10px 10px 10px;
}

.threads-title,
.messages-title {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    flex-direction: row;
}

.threads-title {
    margin-top: 20px;
    margin-bottom: 10px;
}

.messages-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 700px;
    overflow: hidden;
}

.thread-subject {
    margin: 5px 10px;
    font-weight: 600;
    align-self: center;
}

p {
    font-size: 14px;
}

.selected {
    border: 2px solid #470473;
    background-color: #fefbff;
}

.reply-button {
    align-self: center;
}

.reply-container {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.textarea {
    border-radius: 10px;
    margin: 0px 10px 10px 10px;
}

.box-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #b7b1b1;
    border-radius: 20px;
    margin: 40px 40px 40px 40px;
    width: 96%;
    background-color: white;
}

.messages-empty-state {
    display: flex;
    height: 100%;
    align-items: center;
}

.threads-container {
    height: 90%;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #000000 #fbf1ff;
}

.messages-container-container {
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #000000 #ffffff;
}

.refresh-button {
    margin-left: 8px;
    margin-top: -2px;
}

.messages-loader {
    display: flex;
    justify-content: center;
}
</style>
